"use strict";

function App() {
  if (!$) {
    window.$ = jQuery;
  }

  // slick

  $(".js-impression-slider").slick({
    centerMode: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    variableWidth: true,
    centerPadding: "0",
    speed: 600
  });

  // dropdown

  $(document).on("click", function (e) {
    var dropdownContainer = $(".js-dropdown");
    var dropdownList = dropdownContainer.find(".js-dropdown-list");
    if (dropdownContainer.hasClass("opened")) {
      if (!dropdownContainer.is(e.target) && dropdownContainer.has(e.target).length === 0) {
        dropdownContainer.removeClass("opened");
        dropdownList.slideUp(300);
      }
    }
  });
  var dropdownHead = $(".js-dropdown-head");
  dropdownHead.on("click", function (e) {
    var dropdownContainer = $(this).closest(".js-dropdown");
    var dropdownList = dropdownContainer.find(".js-dropdown-list");
    if (dropdownContainer.hasClass("opened")) {
      dropdownContainer.removeClass("opened");
      dropdownList.slideUp(300);
    } else {
      dropdownContainer.addClass("opened");
      dropdownList.slideDown(300);
    }
  });

  // tooltip position

  $(".testimonials__item").each(function () {
    var leftValue = window.getComputedStyle(this).getPropertyValue("left");
    var testimonialsContainer = $(".testimonials__people").innerWidth();
    var value = parseFloat(leftValue);
    var percentValue = value * 100 / testimonialsContainer;
    if (percentValue < 50) {
      $(this).addClass("left-point");
    }
  });

  // drawer func

  var drawerContainer = document.querySelector(".js-drawer-container");
  var drawer = drawerContainer.querySelector(".js-drawer");
  var drawerOutsideClickHandler = function drawerOutsideClickHandler(event) {
    if (!event.target.closest(".js-open-drawer, .drawer-container")) {
      closeDrawer();
    }
  };
  var body = document.querySelector('body');
  var closeDrawer = function closeDrawer() {
    body.classList.remove("show-drawer");
    drawer.classList.remove("active");
    document.body.removeEventListener("click", drawerOutsideClickHandler);
    drawerTriggers.forEach(function (item) {
      return item.classList.remove("active");
    });
  };
  var openDrawer = function openDrawer(id, element) {
    closeDrawer();
    var closeButton = "\n<svg class=\"drawer-close\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M18 6L6 18\" stroke=\"white\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M6 6L18 18\" stroke=\"white\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>";
    drawer.innerHTML = closeButton + element;
    body.classList.add("show-drawer");
    drawer.classList.remove("active");
    drawer.classList.add("active");
    var closeButtonElement = drawer.querySelector(".drawer-close");
    if (closeButtonElement) {
      closeButtonElement.addEventListener("click", closeDrawer);
    }
    document.body.addEventListener("click", drawerOutsideClickHandler);
  };
  var drawerTriggers = document.querySelectorAll(".js-open-drawer");
  drawerTriggers.forEach(function (item) {
    item.addEventListener("click", function () {
      if (this.hasAttribute("data-drawer")) {
        var drawerValue = this.getAttribute("data-drawer");
        item.classList.add("active");
        var content = item.querySelector(".js-drawer-inner");
        openDrawer(drawerValue, content.outerHTML);
      }
    });
  });
}
App();